<template>
  <div class="container-fluid">
    <!--USER ID-->
    <b-row>
      <b-col>
        <b-card
          style="width: 300px; position: absolute; right: 15px; top: -30px"
        >USER ID
          <b-card
            class="bg-danger position-absolute text-white"
            style="right: 0; top: 0"
          ><b>{{ user.id }}</b></b-card></b-card>
      </b-col>
    </b-row>
    <!--USER PROFILE-->
    <b-card class="profile-card my-5 ml-5">
      <b-avatar
        class="offset-left"
        :src="$s3URL + 'uploads/user/' + user.pic"
        size="12rem"
        variant="primary"
      />
      <b-row
        align-v="center"
        class="avatar-offset"
      >
        <b-col class="">
          <div>
            <h4>{{ user.name }}</h4>
            <p>{{ user.phone }}</p>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="profile-card ml-5">
      <feather-icon
        icon="ShoppingBagIcon"
        size="40"
        class="text-primary position-absolute"
      />
      <!--NATURE OF BUSINESS-->
      <b-row
        align-v="center"
        class="ficon-offset"
      >
        <b-col
          align-v="center"
          class="details border-left"
        >
          <h4>NATURE OF BUSINESS</h4>
          <p>B2B</p>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="profile-card ml-5">
      <feather-icon
        icon="MapPinIcon"
        size="40"
        class="text-primary position-absolute"
      />
      <!--ADDRESSES-->
      <b-row
        align-v="center"
        class="ficon-offset"
      >
        <b-col
          align-v="center"
          class="details border-left"
        >
          <h4>ADDRESSES</h4>
          <div
            v-for="address in user.addresses"
            :key="address.id"
          >
            <p>
              <b>{{ address.name }}</b><br>
              <i>{{ address.phone }}</i><br>
              {{ address.complete }}
            </p>
            <hr>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <!--PHONE-->
      <b-col>
        <b-card class="profile-card ml-5">
          <feather-icon
            icon="PhoneIcon"
            size="40"
            class="text-primary position-absolute"
          />
          <b-row
            align-v="center"
            class="ficon-offset"
          >
            <b-col
              align-v="center"
              class="details border-left"
            >
              <h4>PHONE</h4>
              <p>{{ user.a_phone }}</p>
              <p class="invisible">
                {{}}
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col>
        <!--INCHARGE-->
        <b-card class="profile-card">
          <feather-icon
            icon="UserIcon"
            size="40"
            class="text-primary position-absolute"
          />
          <b-row
            align-v="center"
            class="ficon-offset"
          >
            <b-col
              align-v="center"
              class="details border-left"
            >
              <h4>IN-CHARGE</h4>
              <p>{{ user.a_name }}</p>
              <p>{{ user.email }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!--COMPANY DOCUMENTS-->
    <b-card class="profile-card ml-5">
      <feather-icon
        icon="ShoppingBagIcon"
        size="40"
        class="text-primary position-absolute"
      />
      <b-row
        align-v="center"
        class="ficon-offset"
      >
        <b-col
          align-v="center"
          class="details border-left"
        >
          <h4>Company Documents</h4>
          <div>
            <p>
              <b-link v-b-modal.ssm>
                SSM Document
              </b-link>
            </p>
          </div>
        </b-col>
        <b-modal
          id="ssm"
          title="SSM"
          ok-only
          ok-title="Close"
          size="lg"
        >
          <b-card-text>
            <div
              class="container"
              style="min-height: 600px"
            >
              <iframe
                style="
                  position: absolute;
                  width: calc(100% - 65px);
                  height: 100%;
                  border: none;
                "
                :src="$s3URL + 'uploads/user/' + user.smart_ssm_document"
              />
            </div>
          </b-card-text>
        </b-modal>
      </b-row>
    </b-card>

    <b-row>
      <b-col
        class="text-center"
      ><b-button
        type="button"
        variant="primary"
        class="width-250"
        @click="$router.go(-1)"
      >
        Back
      </b-button></b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      user: [],
    }
  },
  async mounted() {
    const userData = await this.$http.get(`/user/${this.$route.params.id}`)
    this.user = userData.data.data
  },
}
</script>
<style scoped>
.offset-left {
  position: absolute;
  left: -25px;
  top: -25px;
}
.avatar-offset {
  margin-left: 9rem !important;
}
.ficon-offset {
  margin-left: 4rem !important;
}
</style>
